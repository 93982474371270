<template>
  <span>
    <transition name="fade">
      <settings-warning v-if="hasChange" style="animation-duration: 0.5s" />
    </transition>
    <div class="store-options">
      <hcc-card
        :title="$t('configuration.store-options.general')"
        color="gray"
        class="card-body border general-options"
      >
        <div class="mild-width">
          <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.name.title") }}
                <span class="single-text">{{ shopData.nameIdCanonical }}</span>
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.address.title") }}
              </p>
              <hcc-input-places
                size="sm"
                placeholder="Address"
                v-model="shopData.address"
                :label="$t('shop.address')"
                @place_selected="changeByAddress"
                name="Address"
                class="input-places"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.address.description") }}
              </p>
            </div>
          </div>
        </hcc-card>

        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.category.title") }}
              </p>
              <hcc-select
                custom-class="select"
                v-model="shopData.category"
                :placeholder="$t('configuration.store-options.category.description')"
                :options="categories"
                name="category"
                class="input"
                option-label="value"
                track-by="id"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.category.description") }}
              </p>
            </div>
          </div>
        </hcc-card>

        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.description.title") }}
              </p>
              <hcc-textarea
                size="sm"
                placeholder="description"
                v-model="shopData.description"
                v-on:input="hasChanged"
                name="description"
                class="input"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.description.description") }}
              </p>
            </div>
          </div>
        </hcc-card>

        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.image-url.title") }}
              </p>
              <div class="multimedia__drop">
                <vue-file-agent
                  ref="dropZone"
                  :multiple="false"
                  :helpText="$t('configuration.store-options.image-url.title')"
                  :maxFiles="1"
                  :meta="true"
                  :compact="true"
                  theme="list"
                  accept="image/*"
                  :maxSize="maxSize"
                  v-model="image"
                  @select="onFileSelected"
                >
                </vue-file-agent>
              </div>
              <p class="card__description">
                {{ $t("configuration.store-options.image-url.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.background-url.title") }}
              </p>
              <div class="multimedia__drop">
                <vue-file-agent
                  ref="dropZone"
                  :multiple="false"
                  :helpText="$t('configuration.store-options.background-url.title')"
                  :maxFiles="1"
                  :meta="true"
                  :compact="true"
                  theme="list"
                  accept="image/*"
                  :maxSize="maxSize"
                  v-model="background"
                  @select="onBackSelected"
                >
                </vue-file-agent>
              </div>
              <p class="card__description">
                {{ $t("configuration.store-options.background-url.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        </div>
        <div class="mild-width vertical-align">
          <div class="smartphone">
            <label class="label-danger" v-if="hasChange">
              <span>*{{ $t('configuration.options.phone-changes') }}</span>
            </label>
            <div class="content">
              <iframe id="phone-view" :src="urlVisitor" class="phone-content" />
            </div>
          </div>
        </div>
      </hcc-card>
      <hcc-card
        :title="$t('configuration.store-options.title')"
        color="gray"
        class="card-body border"
      >
        <config-card
          :title="$t('configuration.store-options.show-qr.title')"
          :key="`${this.campaign.id}13`"
          :text="$t('configuration.store-options.show-qr.description')"
          v-model="optionModal.showQR"
          @change="hasChanged"
          name="showQR"
        />
        <config-card
          :title="$t('configuration.options.delivery-enabled.title')"
          :key="`${this.campaign.id}14`"
          :text="$t('configuration.options.delivery-enabled.description')"
          v-model="optionModal.deliveryEnabled"
          @change="hasChanged"
          name="deliveryEnabled"
        />
        <config-card
          v-if="isShop"
          :title="$t('configuration.store-options.stock-alert.title')"
          :key="`${this.campaign.id}15`"
          :text="$t('configuration.store-options.stock-alert.description')"
          v-model="optionModal.stockAlert"
          @change="hasChanged"
          name="stockAlert"
        />
        <hcc-card v-if="optionModal.stockAlert && isShop" class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.min-stock.title") }}
              </p>
              <hcc-input
                type="number"
                size="sm"
                min="0"
                placeholder="0"
                v-model.number="optionModal.minStock"
                v-on:input="hasChanged"
                name="minStock"
                class="input-text"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.min-stock.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title" v-if="isShop">
                {{ $t("configuration.store-options.avg-delivery.title") }}
              </p>
              <p class="card__title" v-else>
                {{ $t("configuration.store-options.avg-minutes.title") }}
              </p>
              <hcc-input
                type="number"
                size="sm"
                min="0"
                placeholder="0"
                v-model.number="optionModal.avgMinutes"
                v-on:input="hasChanged"
                name="avgMinutes"
                class="input-text"
              />
              <p class="card__description" v-if="isShop">
                {{ $t("configuration.store-options.avg-delivery.description") }}
              </p>
              <p class="card__description" v-else>
                {{ $t("configuration.store-options.avg-minutes.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.currency.title") }}
              </p>
              <hcc-select
                custom-class="select"
                v-model="optionModal.currency"
                :placeholder="$t('configuration.store-options.currency.description')"
                :options="currencies"
                name="currency"
                class="input-text"
                option-label="value"
                track-by="id"
              />
              <p class="card__description">
                {{ $t("configuration.store-options.currency.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card" v-if="isOwner">
          <div class="align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.store-options.url.title") }}
              </p>
              <hcc-input
                type="text"
                size="sm"
                placeholder="url"
                v-model="optionModal.urlText"
                v-on:input="hasChanged"
                name="Name"
                class="input-text"
                @blur="$v.optionModal.urlText.$touch()"
                :error="$v.optionModal.urlText.$error"
                :errorMessage="$t('configuration.store-options.url.error')"
              />
              <p class="card__description">
                {{ $t('configuration.store-options.url.description') }}
              </p>
            </div>
          </div>
        </hcc-card>
      </hcc-card>
      <hcc-card
          :title="$t('configuration.store-options.payments-method')"
          color="gray"
          class="card-body border"
        >
          <config-card
            :title="$t('configuration.store-options.cash-payments.title')"
            :key="`${this.campaign.id}12`"
            :text="$t('configuration.store-options.cash-payments.description')"
            v-model="optionModal.cashPayments"
            @change="hasChanged"
            name="cashPayments"
          />
          <config-card
            :title="$t('configuration.store-options.card-payments.title')"
            :key="`${this.campaign.id}11`"
            :text="$t('configuration.store-options.card-payments.description')"
            v-model="optionModal.cardPayments"
            @change="hasChanged"
            name="cardPayments"
          />
          <hcc-card v-if="optionModal.cardPayments" class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                  {{ $t("configuration.store-options.conekta-publickey.title") }}
                </p>
                <hcc-input
                  type="text"
                  size="sm"
                  placeholder="Public-key"
                  v-model.number="optionModal.conektaPublicKey"
                  v-on:input="hasChanged"
                  name="conektaPublicKey"
                  class="input-text"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.conekta-publickey.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
          <hcc-card v-if="optionModal.cardPayments" class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                  {{ $t("configuration.store-options.conekta-secretkey.title") }}
                </p>
                <hcc-input
                  type="text"
                  size="sm"
                  placeholder="Secret-key"
                  v-model.number="optionModal.conektaSecretKey"
                  v-on:input="hasChanged"
                  name="conektaSecretKey"
                  class="input-text"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.conekta-secretkey.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
          <hcc-card v-if="optionModal.cardPayments" class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                  {{ $t("configuration.store-options.mercado-pago-publickey.title") }}
                </p>
                <hcc-input
                  type="text"
                  size="sm"
                  placeholder="Public-key"
                  v-model.number="optionModal.mercadoPagoPublicKey"
                  v-on:input="hasChanged"
                  name="conektaPublicKey"
                  class="input-text"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.mercado-pago-publickey.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
          <hcc-card v-if="optionModal.cardPayments" class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                  {{ $t("configuration.store-options.mercado-pago-secretkey.title") }}
                </p>
                <hcc-input
                  type="text"
                  size="sm"
                  placeholder="Secret-key"
                  v-model.number="optionModal.mercadoPagoSecretKey"
                  v-on:input="hasChanged"
                  name="conektaSecretKey"
                  class="input-text"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.mercado-pago-secretkey.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
      </hcc-card>
      <hcc-card
          title="Templates"
          color="gray"
          class="card-body border"
        >
          <hcc-card class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                  {{ $t("configuration.store-options.templates.confirm.title") }}
                </p>
                <hcc-select
                  custom-class="select"
                  v-model="optionModal.templateConfirmOrder"
                  :placeholder="$t('configuration.store-options.templates.confirm.description')"
                  :options="templates"
                  name="element_name"
                  class="input-text"
                  option-label="element_name"
                  track-by="id"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.templates.confirm.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
          <hcc-card class="custom-card">
            <div class="align-middle align-justify item-card">
              <div class="column-left">
                <p class="card__title">
                   {{ $t("configuration.store-options.templates.change-status.title") }}
                </p>
                <hcc-select
                  custom-class="select"
                  v-model="optionModal.templateChangeStatus"
                  :placeholder="
                    $t('configuration.store-options.templates.change-status.description')"
                  :options="templates"
                  name="element_name"
                  class="input-text"
                  option-label="element_name"
                  track-by="id"
                />
                <p class="card__description">
                  {{ $t("configuration.store-options.templates.change-status.description") }}
                </p>
              </div>
            </div>
          </hcc-card>
          <hcc-button
            type="submit"
            size="md"
            class="submit"
            :disabled="!disableButton"
            @click="save()"
            >{{ $t("common.save") }}
          </hcc-button>
      </hcc-card>
    </div>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  createNameCanonical,
  saveImage,
  getDataImageStore,
  validateUrlMarketplace,
} from '@/utils/helper';
import editShopMutation from '@/graphql/mutations/campaign/updateShopCampaign.gql';
import SettingsWarning from './SettingsWarning.vue';

const validUrl = (value) => {
  if (!value) return true;
  // eslint-disable-next-line
  const validate = value.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/)
  // eslint-disable-next-line
  return validate?.length ? true : false;
};
export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccTextarea: () => import('@/components/shared/HccTextarea/index.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    ConfigCard: () => import('@/components/settings/ConfigCard.vue'),
    HccInputPlaces: () => import('@/components/shared/HccInputPlaces/index.vue'),
    SettingsWarning,
  },
  data() {
    return {
      options: null,
      isSaving: false,
      hasChange: false,
      errorName: false,
      file: null,
      shopData: {
        name: null,
        address: null,
        category: { id: 'Gastronomía', value: 'Gastronomía' },
        description: null,
        imageUrl: null,
        backgroundUrl: null,
        image: null,
        background: null,
        location: {
          coordinates: [],
          type: 'Point',
        },
      },
      optionModal: {
        cardPayments: false,
        cashPayments: false,
        showQR: false,
        deliveryEnabled: false,
        stockAlert: false,
        minStock: 0,
        avgMinutes: 0,
        conektaPublicKey: null,
        conektaSecretKey: null,
        mercadoPagoPublicKey: null,
        mercadoPagoSecretKey: null,
        currency: { id: 'MXN', value: 'MXN' },
        urlText: '',
        templateConfirmOrder: '',
        templateChangeStatus: '',
      },
      urlTextBase: '',
      originalData: {},
      originalShop: {},
      messages: [],
      nameIdCanonical: null,
      image: [],
      background: [],
    };
  },
  validations: {
    optionModal: {
      urlText: {
        valid: url => validUrl(url),
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedStore: 'shop/getCurrentShop',
      isOwner: 'shared/isOwner',
      templates: 'databank/getTemplates',
    }),
    disableButton() {
      if (this.hasChange || this.isSaving) {
        return true;
      }
      return false;
    },
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
    isShop() {
      return this.shopData.category.value === 'Tienda';
    },
    updatedData() {
      return Object.values(this.optionModal).join();
    },
    updatedShop() {
      return Object.values(this.shopData).join();
    },
    categories() {
      return [
        { id: 'Gastronomía', value: 'Gastronomía' },
        { id: 'Tienda', value: 'Tienda' },
      ];
    },
    currencies() {
      return [
        { id: 'MXN', value: 'MXN' },
        { id: 'USD', value: 'USD' },
      ];
    },
    urlVisitor() {
      const campaignId = this.$route.params.campaign_id;
      if (this.selectedStore && this.selectedStore.settings?.url) {
        return `${this.selectedStore.settings?.url}/${campaignId}/demo`;
      }
      const category = this.selectedStore?.category || this.shopData.category || 'Gastronomía';
      const defaultUrl = this.validateUrl(category);

      return `${defaultUrl}/${campaignId}/demo`;
    },
  },
  mounted() {
    if (this.$route.params.campaign_id) {
      this.getShopInfo();
    }
  },
  watch: {
    'campaign.id': function watchId(newState) {
      if (newState) {
        this.getShopInfo();
      }
    },
    'shopData.category.value': function watchId(newState, oldState) {
      if (oldState && newState) {
        if (oldState !== newState) {
          this.hasChange = true;
        }

        if (this.selectedStore.category === newState) {
          this.hasChange = false;
        }
      }
    },
    'optionModal.currency.value': function watchId(newState, oldState) {
      if (oldState && newState) {
        if (oldState !== newState) {
          this.hasChange = true;
        }

        if (this.selectedStore.settings.currency === newState) {
          this.hasChange = false;
        }
      }
    },
    'optionModal.templateConfirmOrder.id': function watchId(newState, oldState) {
      if (oldState && newState) {
        if (oldState !== newState) {
          this.hasChange = true;
        }

        if (this.selectedStore.settings.templateConfirmOrder === newState) {
          this.hasChange = false;
        }
      }
    },
    'optionModal.templateChangeStatus.id': function watchId(newState, oldState) {
      if (oldState && newState) {
        if (oldState !== newState) {
          this.hasChange = true;
        }

        if (this.selectedStore.settings.templateChangeStatus.template === newState) {
          this.hasChange = false;
        }
      }
    },
    selectedStore(newState) {
      if (newState) {
        this.getStoreSettings();
      }
    },
    templates(newValue) {
      if (this.selectedStore) {
        this.getTemplates(newValue);
      }
    },
  },
  methods: {
    ...mapActions({
      setSelectedStore: 'shop/getById',
      updateStore: 'shop/update',
    }),
    async getTemplates(templates) {
      const shop = this.selectedStore;
      this.optionModal.templateConfirmOrder = templates
        .find(({ id }) => id === shop.settings?.templateConfirmOrder?.template);
      this.optionModal.templateChangeStatus = templates
        .find(({ id }) => id === shop.settings?.templateChangeStatus?.template);
    },
    async getShopInfo() {
      await this.setSelectedStore(this.campaign.id);
    },
    hasChanged() {
      this.hasChange = (this.updatedData !== this.originalData
        || this.updatedShop !== this.originalShop);
    },
    changeByAddress(address) {
      const lat = address.geometry.location.lat();
      const long = address.geometry.location.lng();
      const {
        place_id: placeId,
        formatted_address: formattedAddress,
      } = address;

      this.shopData.address = formattedAddress;
      this.shopData.placeId = placeId;
      this.shopData.location.coordinates = [long, lat];
      this.shopData.location.type = 'Point';
      this.hasChange = true;
    },
    onFileSelected(files) {
      this.shopData.image = files[0].file;
      this.hasChange = true;
    },
    onBackSelected(files) {
      this.shopData.background = files[0].file;
      this.hasChange = true;
    },
    async getStoreSettings() {
      if (this.selectedStore) {
        const shop = this.selectedStore;
        this.shopData.name = this.campaign.name;
        this.shopData.nameIdCanonical = createNameCanonical(this.campaign.name);
        this.shopData.address = shop.address;
        this.shopData.placeId = shop.placeId;
        this.shopData.location.coordinates = shop.location.coordinates;
        this.shopData.location.type = 'Point';
        this.shopData.category = { id: shop.category, value: shop.category };
        this.shopData.description = shop.description;

        if (shop.imageUrl) {
          const imageData = await getDataImageStore({
            nombre: `img-url${shop.nameIdCanonical}`,
            imageURL: shop.imageUrl,
            ...shop,
          });
          this.image = [imageData];
        } else {
          this.image = [];
        }

        this.shopData.imageUrl = shop.imageUrl;

        if (shop.backgroundUrl) {
          const backData = await getDataImageStore({
            nombre: `back-url${shop.nameIdCanonical}`,
            imageURL: shop.backgroundUrl,
            ...shop,
          });
          this.background = [backData];
        } else {
          this.background = [];
        }
        this.shopData.backgroundUrl = shop.backgroundUrl;

        this.optionModal.cardPayments = shop.settings?.cardPayments || false;
        this.optionModal.cashPayments = shop.settings?.cashPayments || false;
        this.optionModal.showQR = shop.settings?.showQR || false;
        this.optionModal.deliveryEnabled = shop.settings?.deliveryEnabled || false;
        this.optionModal.stockAlert = shop.settings?.stockAlert || false;
        this.optionModal.minStock = (this.optionModal.stockAlert) ? shop.settings?.minStock : 0;
        this.optionModal.avgMinutes = shop.settings?.avgMinutes || 0;
        this.optionModal.currency = shop.settings?.currency
          ? { id: shop.settings.currency, value: shop.settings.currency }
          : { id: 'MXN', value: 'MXN' };
        this.optionModal.urlText = !validateUrlMarketplace(shop.settings.url)
          ? shop.settings.url
          : '';
        this.urlTextBase = this.optionModal.urlText;
        this.getTemplates(this.templates);
        this.validatePaymentMethod(shop.paymentMethod);
      }

      this.originalData = Object.values(this.optionModal).join();

      this.originalShop = Object.values(this.shopData).join();
    },
    validatePaymentMethod(methods) {
      methods.forEach((item) => {
        if (item.key === 'conekta') {
          this.optionModal.conektaPublicKey = item.publicKey;
          this.optionModal.conektaSecretKey = item.secretKey;
        }
        if (item.key === 'mercado-pago') {
          this.optionModal.mercadoPagoPublicKey = item.publicKey;
          this.optionModal.mercadoPagoSecretKey = item.secretKey;
        }
      });
    },
    validateTypePayment() {
      const typePayment = [
        ['conektaPublicKey', 'conektaSecretKey', 'conekta'],
        ['mercadoPagoPublicKey', 'mercadoPagoSecretKey', 'mercadoPago'],
      ];

      const paymentToValidate = typePayment
        .filter(item => this.optionModal[item[0]] || this.optionModal[item[1]]);
      if (!paymentToValidate.length) {
        this.$toasted.global.error(this.$t('configuration.options.error-empty-payment'));
        return false;
      }
      let continuePayment = true;
      paymentToValidate.forEach((item) => {
        if (!this.optionModal[item[0]] || !this.optionModal[item[1]]) {
          this.$toasted.global.error(this.$t(`configuration.options.error-empty-${item[2]}`));
          continuePayment = false;
        }
      });
      return continuePayment;
    },
    async save() {
      this.isSaving = true;

      if (this.shopData) {
        const campaign = this.campaign.id;
        const shopInfo = this.shopData;
        shopInfo.category = this.shopData.category.value || 'Gastronomía';
        if (this.optionModal.cardPayments) {
          if (!this.validateTypePayment()) {
            this.isSaving = false;
            return;
          }
        }

        if (this.errorName) {
          this.$toasted.global.error(this.$t('configuration.options.error-long-name'));
          this.isSaving = false;
          return;
        }
        const paymentMethod = [
          {
            service: 'Conekta',
            key: 'conekta',
            publicKey: String(this.optionModal.conektaPublicKey),
            secretKey: String(this.optionModal.conektaSecretKey),
          },
          {
            service: 'Mercado pago',
            key: 'mercado-pago',
            publicKey: String(this.optionModal.mercadoPagoPublicKey),
            secretKey: String(this.optionModal.mercadoPagoSecretKey),
          },
        ];
        const settings = this.optionModal;
        if (this.optionModal.cardPayments) {
          settings.conektaPublicKey = String(this.optionModal.conektaPublicKey);
          settings.conektaSecretKey = String(this.optionModal.conektaSecretKey);
        }
        settings.currency = this.optionModal.currency.value || 'MXN';
        settings.templateConfirmOrder = this.optionModal.templateConfirmOrder.id;
        settings.templateChangeStatus = this.optionModal.templateChangeStatus.id;
        settings.url = this.optionModal.urlText
          ? this.optionModal.urlText
          : this.validateUrl(shopInfo.category);
        delete settings.urlText;
        const data2Update = {
          campaign,
          ...shopInfo,
          settings,
          paymentMethod,
          updatedAt: new Date(),
        };

        data2Update.nameIdCanonical = createNameCanonical(data2Update.name);

        if (data2Update.image) {
          data2Update.imageUrl = await saveImage(data2Update.image, campaign, `logo-${data2Update.nameIdCanonical}`);
        }

        if (data2Update.background) {
          data2Update.backgroundUrl = await saveImage(data2Update.background, campaign, `back-${data2Update.nameIdCanonical}`);
        }
        const result = await this.$apollo.mutate({
          mutation: editShopMutation,
          variables: data2Update,
        });

        await this.updateStore(result.data.updateShopCampaign);

        this.hasChange = false;
        this.$toasted.global.success(this.$t('configuration.options.updated'));
        this.originalData = Object.values(this.optionModal).join();
        this.originalShop = Object.values(this.shopData).join();
        this.isSaving = false;
        document.getElementById('phone-view').src += '';
      }
    },
    validateUrl(type) {
      return type === 'Tienda'
        ? process.env.VUE_APP_BASE_URL_RETAIL
        : process.env.VUE_APP_BASE_URL_GASTRONOMIA;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_store-options-tab.scss";
</style>
